body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar i.fa {
  margin-right: 2px;
}

.navbar-brand {
  font-weight: bold;
}

#github-banner img {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  z-index: 15;
}

#loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 14;
}

#loading-overlay .progress {
  width: 76%;
  /*height: 10%;*/
  height: 30px;
  position: absolute;
  top: 50%;
  left: 12%;
  border: 6px solid grey;
}

#loading-overlay .progress-bar {
  width: 100%;
  height: 100%;
}

#last-updated {
  color: rgba(255, 255, 255, 0.65);
  font-size: 80%;
}

#probe-filters {
  font-size: 90%;
}

#probe-filters .form-control {
  font-size: 90%;
}

#probe-detail-view {
  padding-top: 20px;
}

#detail-probe-name {
  display: inline;
}

#stats {
  font-style: italic;
  font-size: 90%;
  margin-bottom: 10px;
}

#measurements {
  padding: 0px !important;
}

#search-results-table {
  width: 100%;
}

#search-results-table td {
  cursor: pointer;
  white-space: nowrap;
}

.search-results-field-name,
.search-results-field-description {
  max-width: 400px;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.code {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New;
  font-size: 85%;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table td.grow,
.table th.grow {
  white-space: nowrap;
  width: 100%;
}

.hidden {
  display: none;
}

#detail-cpp-guard:empty:after, #detail-bug-numbers:empty:after {
  content: "none";
  font-style: italic;
}

.nav-item {
  font-weight: bold;
}

th {
  position: sticky;
  top: -1px;
  background-color: #ECECEC;
}

#detail-probe-name {
  overflow-wrap: break-word;
}

.infolist {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* added post-reactification */
:root {
  --primary-color: #2780e3;
  --beta-color: #bb1c0c;
  --disabled-color: #bbb;
}

.stats-view #version-selection-element,
.stats-view #text-search-element,
.stats-view #optout-selection-element,
.stats-view #select_channel option:first-child {
  display: none;
}

.nav-item button {
  color: rgba(255,255,255,0.75);
  font-weight: bold;
  margin-top: 2px;
}
.nav-item button:hover {
  color: rgb(255,255,255);
}

.container-full.loading {
  background-color: var(--primary-color);
  overflow: hidden;
  height: 100vh;
}

.container-full.loading > * {
  display: none;
}

.container-full.loading .spinner {
  display: block;
}

/* category histogram labels on the probe details page */
.cat-labels {
  display: flex;
  list-style: none;
  margin: 5px 0 5px 5px;
  padding: 0;
  justify-content: flex-start;
}

.cat-labels li {
  padding: 3px 8px;
  margin-right: 10px;
  background-color: rgba(0,0,0,0.05);
  border-radius: 4px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bbb;
  padding: 0 15px 15px 0;
  margin-bottom: 15px;
}

.paginator {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.paginator li {
  margin-right: 5px;
}

.paginator li:last-child {
  margin-right: 0;
}

.paginator button, .paginator .current-page {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
  line-height: 35px;
  padding: 0 13px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.paginator button:hover, .paginator .active button, .paginator .current-page {
  background-color: #fff;
  color: var(--primary-color);
}

.paginator button:disabled, .paginator button:disabled:hover {
  background-color: var(--disabled-color);
  color: #fff;
  border: 1px solid var(--disabled-color);
}

.paginator .active button {
  cursor: default;
  pointer-events: none;
}

#detail-body i.fa {
  font-size: 13px;
  font-weight: 600;
}

.history-item {
  margin: 5px 0;
}

.experimental-warning {
  font-style: italic;
  font-size: 13px;
  margin: 6px 0;
}

#search {
  min-width: 500px;
}
